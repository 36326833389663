// @flow

import React from 'react';

import type { FullUserType } from 'types/User';
import { handleTagEvent } from 'utils/tagManagerUtils';

type Props = $Exact<{
  club: string,
  hasLicence: boolean,
  ligue: string,
  isClubOfficial: boolean,
  isReferee: boolean,
  userPref: FullUserType
}>;

type State = { isOpen: boolean };

const aAttr = {
  target: '_blank',
  rel: 'noopener noreferrer',
  className: 'link-competition link-competition--card link-competition--access box'
};

export default class QuickAccess extends React.PureComponent<Props, State> {
  state = { isOpen: true };

  getHandleTageEvent = (msg: string) => {
    const { userPref } = this.props;

    return handleTagEvent('espace perso', 'clic_lien_acces_rapide', msg, userPref);
  };

  onClick = () => this.setState(({ isOpen }) => ({ isOpen: !isOpen }));

  render() {
    const { isOpen } = this.state;
    let { club, hasLicence, ligue, isClubOfficial, isReferee } = this.props;

    return (
      <div className={`dashboard-menu__access ${isOpen ? '' : 'hide-dashboard-links'}`}>
        <p className="ft-700 mb-2 ft-h5 dashboard-link-header" onClick={this.onClick}>
          Accès rapide
          <i className={`icon icon-chrevron-${isOpen ? 'down' : 'up'}`} />
        </p>

        <div className="dashboard-links">
          {!isReferee && !isClubOfficial && (
            <a
              {...aAttr}
              href="https://competitions.ffr.fr/"
              onClick={this.getHandleTageEvent('Competitions')}
              title="Compétitions de la FFR"
            >
              <span className="link-competition__title ft-h4">
                Résultats des compétitions
                <i className="icon icon-external" />
              </span>
            </a>
          )}

          {hasLicence && (
            <a {...aAttr} href="https://ovale2.ffr.fr/" onClick={this.getHandleTageEvent('Ovale')} title="Oval-e">
              <span className="link-competition__title ft-h4">
                Oval-e
                <i className="icon icon-external" />
              </span>
            </a>
          )}

          {isClubOfficial && (
            <a
              {...aAttr}
              href="https://cote-dirigeants.ffr.fr/"
              onClick={this.getHandleTageEvent('Cote dirigeants')}
              title="Côté dirigeants"
            >
              <span className="link-competition__title ft-h4">
                Côté dirigeants
                <i className="icon icon-external" />
              </span>
            </a>
          )}

          {isReferee && (
            <a
              {...aAttr}
              href="https://formation.ffr.fr/"
              onClick={this.getHandleTageEvent('Formation ' + club)}
              title="Site du club"
            >
              <span className="link-competition__title ft-h4">
                Site formation
                <i className="icon icon-external" />
              </span>
            </a>
          )}

          {!isClubOfficial && (
            <a
              {...aAttr}
              href="https://www.ffr.fr/"
              onClick={this.getHandleTageEvent('FFR.FR')}
              title="Site de la fédération"
            >
              <span className="link-competition__title ft-h4">
                Site fédération
                <i className="icon icon-external" />
              </span>
            </a>
          )}

          {(isReferee || isClubOfficial) && (
            <a
              {...aAttr}
              href="https://competitions.ffr.fr/2020-2021/"
              onClick={this.getHandleTageEvent('Competitions')}
              title="Compétitions de la FFR"
            >
              <span className="link-competition__title ft-h4">
                Résultats des compétitions
                <i className="icon icon-external" />
              </span>
            </a>
          )}

          {isClubOfficial && (
            <a
              {...aAttr}
              href="https://www.ffr.fr/"
              onClick={this.getHandleTageEvent('FFR.FR')}
              title="Site de la fédération"
            >
              <span className="link-competition__title ft-h4">
                Site fédération
                <i className="icon icon-external" />
              </span>
            </a>
          )}

          {!(isReferee || isClubOfficial) && club && (
            <a {...aAttr} href={club} onClick={this.getHandleTageEvent('Club ' + club)} title="Site du club">
              <span className="link-competition__title ft-h4">
                Site club
                <i className="icon icon-external" />
              </span>
            </a>
          )}

          {ligue && (
            <a {...aAttr} href={ligue} onClick={this.getHandleTageEvent('Ligue ' + ligue)} title="Site de la ligue">
              <span className="link-competition__title ft-h4">
                Site ligue
                <i className="icon icon-external" />
              </span>
            </a>
          )}

          {(isReferee || isClubOfficial) && club && (
            <a {...aAttr} href={club} onClick={this.getHandleTageEvent('Club ' + club)} title="Site du club">
              <span className="link-competition__title ft-h4">
                Site club
                <i className="icon icon-external" />
              </span>
            </a>
          )}

          {!isReferee && isClubOfficial && (
            <a
              {...aAttr}
              href="https://formation.ffr.fr/"
              onClick={this.getHandleTageEvent('Formation ' + club)}
              title="Site du club"
            >
              <span className="link-competition__title ft-h4">
                Site formation
                <i className="icon icon-external" />
              </span>
            </a>
          )}

          <a
            {...aAttr}
            href="https://boutique.ffr.fr/"
            onClick={this.getHandleTageEvent('Boutique')}
            title="Boutique de la FFR"
          >
            <span className="link-competition__title ft-h4">
              Boutique
              <i className="icon icon-external" />
            </span>
          </a>

          <a
            {...aAttr}
            href="https://billetterie.ffr.fr/"
            onClick={this.getHandleTageEvent('Billetterie')}
            title="Billetterie de la FFR"
          >
            <span className="link-competition__title ft-h4">
              Billetterie
              <i className="icon icon-external" />
            </span>
          </a>
        </div>
      </div>
    );
  }
}
